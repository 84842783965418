<template>
  <div class="container">
    <div class="w-full">
      <header class="top" style="display: flex; align-items: center; gap: 5px">
        <router-link to="/dashboard">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
              stroke="#747373"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.5019 12H3.67188"
              stroke="#747373"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
        <h2>Notifications</h2>
      </header>
      <div class="grid-notifications">
        <span style="position: absolute" v-if="n_loading">Loading...</span>
        <div class="wrapper">
          <div @click="show = !show" class="action">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 5.83398H17.5"
                stroke="#6A6A6A"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M5 10H15"
                stroke="#6A6A6A"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M8.33594 14.166H11.6693"
                stroke="#6A6A6A"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            <span>Filter by</span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.7702 6.61455L7.53128 10.3534L11.2701 6.59232"
                stroke="#6A6A6A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div v-if="show" class="filter">
            <div
              class="item"
              v-for="filter in filters"
              @click="setFilter(filter)"
              :key="filter"
            >
              <CheckedRadioIcon v-if="filter.id == selectedFilter" />
              <UnCheckedRadioIcon v-else />

              <span> {{ filter.label }}</span>
            </div>
          </div>
        </div>
        <notification
          v-for="(notification, i) in all_notifications.mdData"
          :key="i"
          :notification="notification"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Notification,
  CheckedRadioIcon,
  UnCheckedRadioIcon,
} from "@/components";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("dataStore");

export default {
  components: {
    Notification,
    CheckedRadioIcon,
    UnCheckedRadioIcon,
  },
  data: () => {
    return {
      filters: [
        {
          id: "all_time",
          label: "All Time",
        },
        {
          id: "today",
          label: "Today",
        },
        {
          id: "last_7_days",
          label: "Last 7 Days",
        },
        {
          id: "last_30_days",
          label: "Last 30 Days",
        },
      ],
      show: false,
      selectedFilter: "all_time",
    };
  },
  methods: {
    ...mapActions(["getAllNotifications", "markNotifications"]),
    setFilter(filter) {
      this.selectedFilter = filter.id;
      this.show = false;
      this.filter();
    },
    filter() {
      this.getAllNotifications({ filter: this.selectedFilter });
    },
  },
  mounted() {
    this.getAllNotifications();
    this.markNotifications();
  },
  computed: {
    ...mapGetters(["n_loading", "all_notifications"]),
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  justify-content: end;
  position: relative;

  .filter {
    position: absolute;
    top: 65px;
    right: 0px;
    border-radius: 10px;
    background: #fff;
    min-width: 210px;
    padding: 13px 0px 15px 0px;

    box-shadow: 0px 4px 4px 0px rgba(18, 18, 18, 0.1);
    backdrop-filter: blur(7.5px);

    .item {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 113.5%;
      padding: 13px 10px;
      cursor: pointer;
      display: flex;
      gap: 14px;
      align-items: center;

      &:hover {
        background: #f9f8f8;
      }
    }
  }
}
.action {
  display: inline-flex;
  justify-content: end;
  padding: 12px 22.956px 8px 15px;
  align-items: center;
  margin: 20px 0px;
  border-radius: 20px;
  border: 1px solid #e9e9e9;
  gap: 6px;
  cursor: pointer;
}
</style>
